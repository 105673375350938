.outlets_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.outlets h1 {
    color: #8d7050;
    text-align: center;
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.card.outlet {
    padding: 1rem;
}

.outlet_img {
    /* border-radius: 0 2rem; */
    overflow: hidden;
    pointer-events: none;
}

.outlet h3 {
    margin-top: 2rem;
    color: #8d7050;
}

.outlet p {
    margin-top: 0.5rem;
    color: #8d7050;
}

.buttonContainer {
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: #ffb56b;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
    padding-left: 1.4rem;
    padding-right: 1.4rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 14px;
    border-radius: 0.5rem;
    color: #fff;
    border: 1px solid #ffb56b;
  }

  .buttonContainer:hover {
    background: transparent;
    border-color: #8d7050;
    cursor: default;
  
    color: #ffb56b;
  }

/* MEDIA QUERIES (medium screen) */
@media screen and (max-width: 1024px) {
    .outlets_container {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
}

/* MEDIA QUERIES (small screen) */
@media screen and (max-width: 600px) {
    .outlets_container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .card.outlet {
        width: 90%;
        margin-inline: auto;
    }
}