/* .logo-img {
    max-width: 100px;
    height: 100;
    margin-right: 15px;
} */

nav {
  height: 5rem;
  width: 150vw;
  background-color: wheat;
  display: grid;
  place-items: center;
  /* position: fixed; */
  /* top: 0;
    left: 0;
    z-index: 99; */
}

/* only shows on medium and small screens */
.nav_toggle-btn {
  display: none;
  color: #8d7050;
}

.nav_container {
  /* height: 100%; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: 2rem;
  /* position: relative; */
}

.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: "50%";
  margin-bottom: 3rem;
  margin-top: 2rem;
}

.logo img {
  width: 100%;
}

.nav_links {
  display: flex;
  gap: 3.5rem;
  align-items: center;
}

.nav_links a {
  transition: var(--transition);
  color: #8d7050;
  font-weight: 700;
}

.nav_links a:hover {
  color: var(--color-secondary);
}

.active-nav {
  position: relative;
}

.active-nav::after {
  content: "";
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  background: var(--color-primary);
  position: absolute;
  left: calc(50% - 0.6rem);
  transform: rotate(45deg);
  margin-top: 0.9rem;
}

.contentImg {
  padding-top: 0rem;
}

.contentImg-img {
  width: max-content;
}

/* MEDIA QUARIES (medium and small screens) */
@media screen and (max-width: 1024px) {
  .nav_toggle-btn {
    display: inline-block;
    background: transparent;
    font-size: 1.8rem;
    cursor: pointer;
  }

  .nav_toggle-btn svg {
    color: var(--color-gray-100);
  }

  .nav_links {
    position: absolute;
    top: 220px;
    left: 90px;
    flex-direction: column;
    gap: 0;
    perspective: 400px;
  }

  .active-nav,
  .active-nav::after {
    display: none;
  }

  .nav_links li {
    height: 4rem;
    width: 100%;
    box-shadow: -2rem 2rem 5rem rgba(0, 0, 0, 0.5);
    animation: navAnimation 600ms ease forwards;
    transform: rotateX(90deg);
    opacity: 0;
    transform-origin: top;
    z-index: 99;
  }

  .nav_links li:nth-child(2) {
    animation-delay: 200ms;
  }

  .nav_links li:nth-child(3) {
    animation-delay: 400ms;
  }

  .nav_links li:nth-child(4) {
    animation-delay: 600ms;
  }

  .nav_links li:nth-child(5) {
    animation-delay: 800ms;
  }

  .nav_links li:nth-child(6) {
    animation-delay: 1s;
  }

  .nav_links li:nth-child(7) {
    animation-delay: 1.2s;
  }

  @keyframes navAnimation {
    to {
      transform: rotateX(0);
      opacity: 1;
    }
  }

  .nav_links li a {
    background-color: wheat;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1rem 5rem 1rem 3rem;
  }

  .show_nav {
    display: flex;
    z-index: 99;
  }

  .hide_nav {
    display: none;
  }
}
