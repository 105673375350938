.section-title {
  color: #8d7050;
  font-size: 1.5rem;
}

.row {
  display: flex;
  margin: 0;
  padding: 0;
}

.product-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 2rem;
}

.product h1 {
  color: #8d7050;
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.product-card {
  padding: 0;
  margin: 0;
}

.card .product {
  padding: 1rem;
  margin: 0;
}

.product-img {
  /* border-radius: 0 2rem; */
  /* overflow: hidden;
  pointer-events: none; */
  margin: 8px;
  background-color: #e8e0ca;
}

.product h3 {
  /* margin-top: 2rem; */
  color: #8d7050;
}

.productName {
  margin-top: 2rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.categoryName {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.buttonContainer {
  margin-bottom: 1rem;
  background-color: #ffb56b;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  padding-left: 1.4rem;
  padding-right: 1.4rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 14px;
  color: #fff;
}

.product p {
  font-size: 13px;
  /* margin-top: 0.5rem; */
  color: #8d7050;
}

.contentImg {
  padding-bottom: 2rem;
  overflow: hidden;
  pointer-events: none;
}

.contentImg-img {
  width: max-content;
}

.cert h1 {
  color: #8d7050;
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.cert {
  margin-bottom: 2.5rem;
}

.cert-img {
  width: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  pointer-events: none;
}

.category_img {
  overflow: hidden;
  pointer-events: none;
}

/* MEDIA QUERIES (medium screen) */
@media screen and (max-width: 1024px) {
  .product-container {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }

  .cert-img {
    width: 70%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    pointer-events: none;
  }

  .buttonContainer {
    /* margin-bottom: 1rem; */
    background-color: #ffb56b;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
    padding-left: 1.4rem;
    padding-right: 1.4rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 14px;
    color: #fff;
  }
}

/* MEDIA QUERIES (small screen) */
@media screen and (max-width: 600px) {
  .product-container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .card .product {
    width: 90%;
    margin-inline: auto;
  }

  .cert-img {
    width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    pointer-events: none;
  }
}
