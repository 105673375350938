.contact_container {
  display: grid;
  place-items: center;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.contact_wrapper {
  width: fit-content;
  display: flex;
  gap: 2rem;
}

.contact_wrapper a {
  background: wheat;
  font-size: 2rem;
  border: 2px solid transparent;
  transition: var(--transition);
  padding: 2rem;
  border-radius: 1.5rem;
}

.contact_wrapper a:hover {
  background: transparent;
  border-color: wheat;
  color: wheat;
}

.contact_container > h2 {
  color: #4c2410;
  margin-bottom: 10px;
}

.contact_container > p {
  color: #4c2410;
  text-align: center;
  font-size: 1rem;
}

.contact_container > button {
  border: 1px solid #ffb56b;
  background: #ffb56b;
  color: white;

  font-size: 16px;
  margin-top: 1.5rem;
  padding: 0.85rem;
  border-radius: 10px;
}

.contact_container > button:hover {
  background: transparent;
  border-color: #ffb56b;
  color: #ffb56b;
}

/* MEIDA QUARIES (small screen) */
@media screen and (max-width: 600px) {
  .contact_wrapper {
    gap: 1.5rem;
  }

  .contact_wrapper a {
    padding: 1rem;
    font-size: 1.2rem;
    border-radius: 1rem;
  }
}
