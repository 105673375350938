.category h3 {
  margin-top: 0.5rem;
  color: #8d7050;
  text-align: center;
}

.category h2 {
  margin-top: 0.5rem;
  color: #8d7050;
  text-align: center;
}

.category p {
  font-size: 13px;
  margin-top: 0.5rem;
  color: #8d7050;
}

.category-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.category-col {
  display: flex;
  margin: 0;
  padding: 0;
}

.card {
  margin: 1rem;
}

.btn-next {
  background-color: #ffeec6;
}

/* .card-body {
  pointer-events: none;
} */

.category-card :hover {
  cursor: pointer;
}

.category-title :hover {
  font-weight: bold;
}

.productName {
  margin-top: 2rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
}

.buyButton {
  margin: 1rem;
}

.buttonContainer {
  margin-bottom: 1rem;
  background-color: #ffb56b;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  padding-left: 1.4rem;
  padding-right: 1.4rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 14px;
  color: #fff;
  border: 1px solid #ffb56b;
}

.buttonContainer:hover {
  background: transparent;
  border-color: #ffb56b;
  color: #ffb56b;
}

.product p {
  font-size: 13px;
  /* margin-top: 0.5rem; */
  color: #8d7050;
}

.category_img {
  overflow: hidden;
  pointer-events: none;
}

/* MEDIA QUERIES (medium screen) */
@media screen and (max-width: 1024px) {
  .category-container {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
}

/* MEDIA QUERIES (small screen) */
@media screen and (max-width: 600px) {
  .category-container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}
