/* Footer */
footer {
    background: #000;
    margin-top: 1rem;
    /* margin-top: auto; */
    padding-top: 7rem;
    font-size: 0.9rem;
    color: #ffeec6;
}

.footer_container {
    display: grid;
    grid-template-columns: 26rem 1fr 1fr 1fr;
    gap: 6rem;
}

.footer_container article {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.7rem;
}

.footer_container article p {
    margin-top: 0.5rem;
}

.logo {
    width: 5rem;
    display: block;
}

.footer_container article h4 {
    margin-bottom: 0.6rem;
    font-size: 1rem;
}

.footer_socials {
    margin-top: 1.5rem;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.footer_socials a {
    background: var(--color-gray-100);
    padding: 0.7rem;
    border: 1px solid transparent;
    border-radius: 0.6rem;
    transition: var(--transition);
}

.footer_socials a svg {
    color: var(--color-gray-600);
}

.footer_socials a:hover {
    border-color: var(--color-gray-100);
    background: transparent;
}

.footer_copyright {
    color: var(--color-gray-100);
    text-align: center;
    padding: 1.5rem 0;
    border-top: 2px solid;
    margin-top: 5rem;
}

/* MEDIA QUARIES (medium screens) */
@media screen and (max-width: 1024px) {
.footer_container {
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    }
}

/* MEDIA QUARIES (small screens) */
@media screen and (max-width: 600px) {
    footer {
        margin-top: 7rem;
    }

    .footer_container {
        grid-template-columns: 1fr;
        gap: 3rem;
    }

    .footer_container article {
        align-items: center;
    }

    .footer_container article p {
        text-align: center;
    }
}