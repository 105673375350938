*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}

:root {
  --primary-hue: 0;
  --grey-hue: 240;
  --color-primary: hsl(var(--primary-hue), 0%, 0%);
  --color-primary-varient: hsl(var(--primary-hue), 100%, 65%);
  --color-secondary: #ffb116;
  --color-gray-100: hsl(var(--gray-hue), 47%, 94%);
  --color-gray-200: hsl(var(--gray-hue), 26%, 75%);
  --color-gray-300: hsl(var(--gray-hue), 24%, 64%);
  --color-gray-400: hsl(var(--gray-hue), 16%, 41%);
  --color-gray-500: hsl(var(--gray-hue), 44%, 25%);
  --color-gray-600: hsl(var(--gray-hue), 48%, 15%);

  --container-width-lg: 80%;
  --container-width-md: 90%;

  --transition: all 500ms ease;
}

/* GENERAL STYLES */
body {
  font-family: "Montserrat", sans-serif;
  color: var(--color-gray-200);
  line-height: 1.7;
  overflow-x: hidden;
  background-color: #FFFDF0;
}

.container {
  width: var(--container-width-lg);
  max-width: 1920px;
  margin-inline: auto;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.2;
  color: var(--color-gray-100);
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

a {
  color: var(--color-gray-100);
}

img {
  display: block;
  object-fit: cover;
  width: 100%;
}

section {
  margin-top: 5rem;
  margin-left: 5rem;
  margin-right: 5rem;
}

.section-head {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.section_head span {
  background-color: var(--color-gray-500);
  padding: 0.8rem;
  border-radius: 1rem;
  color: var(--color-secondary);
}

.card {
  background: rgba(255, 253, 240, 0.8);
  border: 2px solid transparent;
  text-align: center;
  padding: 3rem 2rem;
  margin: 3rem;
  /* border-radius: 0 3rem 0 3rem; */
  transition: var(--transition);
  box-shadow: 3px 3px 10px #5b5b5b;
}

.card:hover {
  background: transparent;
  border-color: #8d7050;
  cursor: default;

  color: #ffb116;
}

.card span {
  width: 3rem;
  height: 3rem;
  background: var(--color-primary);
  color: var(--color-gray-100);
  font-size: 1.5rem;
  padding: 0.8rem;
  display: grid;
  place-items: center;
  margin-bottom: 1.5rem;
  margin-inline: auto;
  border-radius: 1rem;
  transition: var(--transition);
}

.card:hover span {
  background: var(--color-secondary);
  color: var(--color-gray-600);
}

.card small {
  margin-top: 1rem;
  display: block;
  font-weight: 300;
}

.header {
  height: 20rem;
  overflow: hidden;
  border-bottom: 2px solid;
}

.header_container {
  width: 100%;
  height: 100%;
  position: relative;
  display: grid;
  place-items: center;
  background: black;
}

.header_container-bg {
  position: absolute;
  width: 100%;
}

.header_container-bg img {
  opacity: 0.5;
}

.header_content {
  position: relative;
  width: 44%;
  margin: 0 auto;
  text-align: center;
  color: wheat;
}

.header_content h2 {
  margin-bottom: 1rem;
}

.header_content p {
  /* color: rgba(255, 255, 255, 0.75); */
  color: wheat;
}

/* MEDIA QUARIES (medium screens) */
@media screen and (max-width: 1024px) {
  body {
    .container {
      width: var(--container-width-md);
    }

    h1 {
      font-size: 2rem;
    }

    h2 {
      font-size: 1.6rem;
    }

    .header_content {
      width: 76%;
    }
  }
}

/* MEDIA QUARIES (small screens) */
@media screen and (max-width: 600px) {
  h1 {
    font-size: 2.2rem;
    line-height: 1.3rem;
  }

  section {
    margin-top: 7rem;
  }

  .header {
    /* height: fit-content; */
    height: 10rem;
  }

  .header_content {
    width: var(--container-width-md);
    padding: 3rem 0;
  }

  .header_content p {
    font-size: 0.85;
  }
}
