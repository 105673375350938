.carousel {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  height: 100%;
  margin-top: 0.1rem;
}

.slide {
  border-radius: 0.5rem;
  box-shadow: 0px 0px 7px #666;
  max-width: 100%;
  height: 100%;
  box-shadow: 0px 0px 10px #000;
  object-fit: contain;
  overflow: hidden;
  pointer-events: none;
}

.slide-hidden {
  display: none;
  overflow: hidden;
  pointer-events: none;
}

.arrow {
  position: absolute;
  width: 2rem;
  height: 2rem;
  color: #fff;
  filter: drop-shadow(0px 0px 5px #555);
}

.arrow:hover {
  cursor: pointer;
}

.arrow-left {
  left: 1rem;
}

.arrow-right {
  right: 1rem;
}

.indicators {
  display: flex;
  position: absolute;
  bottom: 1rem;
}

.indicator {
  background-color: #fff;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 100%;
  border: none;
  outline: none;
  box-shadow: 0px 0px 5px #555;
  margin: 0 0.2rem;
  cursor: pointer;
}

.indicator-inactive {
  background-color: grey;
}

/* MEDIA QUERIES (medium screen) */
@media screen and (max-width: 1024px) {
  .carousel {
    max-width: 1024px;
    height: 500px;
  }

  .slide {
    max-width: 1024px;
    object-fit: inherit;
    overflow: hidden;
    pointer-events: none;
  }
}

/* MEDIA QUERIES (small screen) */
@media screen and (max-width: 600px) {
  .carousel {
    max-width: 600px;
    height: 300px;
  }

  .slide {
    max-width: 600px;
    object-fit: inherit;
    overflow: hidden;
    pointer-events: none;
  }
}
