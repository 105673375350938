.notfound_container {
    text-align: center;
    padding-top: 8rem;
}

.notfound_container h2 {
    color: #8d7050;
}

.notfound_container .btn {
    margin-top: 2rem;
    display: inline-block;
    color: black;
    background: wheat;
    border-radius: 1rem;
    padding: 1rem;
    border: 2px solid transparent;
}

.notfound_container .btn:hover {
    margin-top: 2rem;
    display: inline-block;
    border-width: 1px;
    border-radius: 1rem;
    padding: 1rem;
    background: transparent;
    border-color: #8d7050;
    color: #8d7050;
}

.img_container {
 display: flex;
 justify-content: center;
}

.img {
    width: 70%;
    height: 70%;
    }

/* MEDIA QUARIES (small screens) */
@media screen and (max-width: 600px) {
.img {
    width: 100%;
    height: 100%;
    }
}