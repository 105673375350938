.about_story {
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  margin-right: 2rem;
  flex: 5;
}

.about_image {
  margin-top: 0;
  overflow: hidden;
  pointer-events: none;
}

.about_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.about_content {
  display: flex;
  flex-direction: row;
}

.about_story_left {
  display: flex;
  flex-direction: column;
  flex: 1.5;
  margin-right: 1rem;
}

.about_story_right {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 1rem;
  margin-top: 10rem;
}

.about_section-content {
  display: flex;
  flex-direction: column;
}

.img {
  width: 100%;
  height: 50%;
  overflow: hidden;
  pointer-events: none;
}
.img_2 {
  margin-top: 10rem;
  margin-bottom: 5rem;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
  /* height: 50%; */
}

.img_3 {
  margin-bottom: 5rem;
  overflow: hidden;
  pointer-events: none;
}

.about_section-content h1 {
  color: #8d7050;
  margin-top: 20px;
}

.about_section-content p {
  color: #8d7050;
  margin-top: 25px;
  text-align: justify;
}

footer{
  margin-top: 0;
}

/* MEDIA QUARIES (medium screens) */
@media screen and (max-width: 1024px) {
  .about_section-content h1 {
    color: #8d7050;
    margin-top: 20px;
    font-size: 2.5rem;
  }

  .about_section-content p {
    color: #8d7050;
    margin-top: 20px;
    text-align: justify;
  }

  .about_container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .about_image {
    margin-top: 1rem;
    overflow: hidden;
    pointer-events: none;
  }

  .img {
    margin-top: 1rem;
    height: auto;
    overflow: hidden;
    pointer-events: none;
  }

  .img_2 {
    height: auto;
    margin-top: 1rem;
    overflow: hidden;
    pointer-events: none;
  }
}

/* MEDIA QUARIES (small screens) */
@media screen and (max-width: 600px) {
  .about_section-content h1 {
    color: #8d7050;
    margin-top: 10px;
  }

  .about_section-content p {
    color: #8d7050;
    margin-top: 20px;
    text-align: justify;
  }

  .about_container {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }

  .about_image {
    margin-top: 1.5rem;
    overflow: hidden;
    pointer-events: none;
  }

  .img {
    margin-top: 1rem;
    height: auto;
    overflow: hidden;
    pointer-events: none;
  }

  .img_2 {
    height: auto;
    margin-top: 1rem;
    overflow: hidden;
    pointer-events: none;
  }

 
}
